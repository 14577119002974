import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';
import PageBanner from '../components/PageBanner';
import YAMLData from '../../static/content/products.yml';
import ReactMarkdown from "react-markdown";
import SEO from "../components/SEO";

function ProductPage() {
  const imageData = useStaticQuery(graphql`
    query {
      products: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 600
              )
            }
          }
        }
      }
      banners: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 1400
              )
            }
          }
        }
      }
    }
  `);

  const {
    productsList,
    heading,
    image,
    image_alt = ''
  } = YAMLData;

  productsList.map((product, i) => {
    let productImage = imageData.products.edges.find(
      productImage => "/assets/"+productImage.node.relativePath === product.image
    )
    product.imageSrc = productImage.node.childImageSharp.gatsbyImageData;
    product.isOdd = (i % 2);
    return product;
  });

  const headerImage = imageData.banners.edges.find(
    headerImage => "/assets/"+headerImage.node.relativePath === image
  );


  const ProductImage = ({product}) => {
    return (
      <div className="image">
        <GatsbyImage alt={product.image_alt}
          image={product.imageSrc} />
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Fair Beauty - Our Products | Top quality & local beauty products."
        description="We offer an extensive range of top quality and local beauty products for waxing, skincare, piercings, and more." />
      <PageBanner image={headerImage.node.childImageSharp.gatsbyImageData}
        imageAlt={image_alt}
        heading={heading} />

      <section className="section">
        <div className="container grid is-vertical">
          <div className="column">
            {productsList.map((product, i) => (
              <div className="media"
                key={i}>
                {!product.isOdd
                  ? <div className="media__left is-tablet-up">
                    <ProductImage product={product} />
                  </div>
                  : ''
                }
                <div className="media__content">
                  <div className="is-mobile-only"
                    style={{paddingBottom: '10px'}}>
                    <ProductImage product={product} />
                  </div>
                  <h4>{product.heading}</h4>
                  <ReactMarkdown children={product.info}/>
                </div>
                {product.isOdd
                  ? <div className="media__right is-tablet-up">
                    <ProductImage product={product} />
                  </div>
                  : ''
                }
              </div>
            ))}

          </div>
        </div>
      </section>
    </Layout>
  )
}


export default ProductPage;
